import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBQd_tbkFwO-RN0r-5mf-b8UG7p9QcqI-Y",
  authDomain: "mail-npm.firebaseapp.com",
  projectId: "mail-npm",
  storageBucket: "mail-npm.appspot.com",
  messagingSenderId: "822115847952",
  appId: "1:822115847952:web:7ba6f3c59d6846f8631f95",
  measurementId: "G-NYKRSEVZRK",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };
