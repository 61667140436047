import {
  FailedtoSignin,
  FailedtoSigninout,
  Signin,
  Signinout,
  StartSignin,
  StartSignout,
} from "../store/Authslice";
import { onAuthStateChanged, signInWithPopup, signOut } from "firebase/auth";
import { auth, provider } from "./Firebase";

const SIGNIN = async (dispatch) => {
  dispatch(StartSignin());
  try {
    const result = await signInWithPopup(auth, provider);
    if (result) {
      dispatch(Signin(result.user));
    }
  } catch (err) {
    dispatch(FailedtoSignin(err));
  }
};

const SIGNOUT = async (dispatch) => {
  dispatch(StartSignout());
  try {
    await signOut(auth);
    dispatch(Signinout());
  } catch (err) {
    dispatch(FailedtoSigninout(err));
  }
};

const RELOAD = (dispatch) => {
  dispatch(StartSignin());
  onAuthStateChanged(auth, (currentUser) => {
    dispatch(Signin(currentUser));
  });
};

export { SIGNIN, SIGNOUT, RELOAD };
