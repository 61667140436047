import { Backdrop } from "@mui/material";
import React from "react";

export default function Loading({ open }) {
  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: 99999 }} open={open}>
        Loading
      </Backdrop>
    </>
  );
}
