import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import LOGO from "../../Assets/ThriveProSVG.svg";
import { useSelector } from "react-redux";

function MyAppBar() {
  const user = useSelector((state) => state.auth.user);

  return (
    <AppBar position="fixed" color="secondary">
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <img src={LOGO} alt="logog" width={100} />
        <Typography>{user?.displayName}</Typography>
      </Toolbar>
    </AppBar>
  );
}

export default MyAppBar;
