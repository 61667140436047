import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  loading: false,
  error: null,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    StartSignin(state, action) {
      state.user = null;
      state.loading = true;
      state.error = null;
    },
    Signin(state, action) {
      state.user = action.payload;
      state.loading = false;
      state.error = null;
    },
    FailedtoSignin(state, action) {
      state.user = null;
      state.loading = false;
      state.error = action.payload;
    },
    //-------------------------------------------------------------
    StartSignout(state) {
      state.loading = true;
      state.error = null;
    },
    Signinout(state, action) {
      state.user = null;
      state.loading = false;
      state.error = null;
    },
    FailedtoSigninout(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default AuthSlice.reducer;
export const {
  StartSignin,
  Signin,
  FailedtoSignin,
  StartSignout,
  Signinout,
  FailedtoSigninout,
} = AuthSlice.actions;
