import { Box, Button, ThemeProvider } from "@mui/material";
import "./App.css";
import MyAppBar from "./Components/Bar/MyAppBar";
import { theme } from "./Theme";
import { useDispatch, useSelector } from "react-redux";
import { RELOAD, SIGNIN, SIGNOUT } from "./Config/Auth";
import Loading from "./Components/Loading";
import { useEffect } from "react";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.auth.loading);

  const SignIn = async () => {
    SIGNIN(dispatch);
  };

  const handleSignOut = () => {
    SIGNOUT(dispatch);
  };

  useEffect(() => {
    RELOAD(dispatch);
  }, [dispatch]);
  return (
    <ThemeProvider theme={theme}>
      <MyAppBar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {loading ? (
          <Loading open={loading} />
        ) : user ? (
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{ color: "#fff" }}
              onClick={handleSignOut}
            >
              Sign Out
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            sx={{ color: "#fff" }}
            onClick={SignIn}
          >
            Sign In
          </Button>
        )}
      </Box>
    </ThemeProvider>
  );
}

export default App;
